.section1_wrapper{
  position: initial;
  padding-top: 100px;
  padding-bottom: 50px;
  background-color: white !important;
}

.section1_wrapper .s1_right H3{
  margin-bottom: 30px;
}

.section1_wrapper .s1_right{
  padding-top: 100px;
  padding-bottom: 50px;
}

.section1_wrapper .section1_p{
  margin-bottom: 50px;
  text-align: justify;
}

.section1_wrapper .s1_right a{
  border-radius: 0px;
  padding: 15px 30px;
  background-color: rgb(2, 112, 17);
}
.section1_wrapper .s1_right a:hover, .section1_wrapper .s1_right a:active, .section1_wrapper .s1_right a:focus, .section1_wrapper .s1_right a:focus-within{
  background-color: rgb(250, 203, 23);
  border: 1px solid rgb(250, 203, 23);
  box-shadow: 0 0 0 0.2rem rgba(250, 203, 23, 0.5)
}

.section1_wrapper .s1_img{
  padding-top: 70px;
}

.section1_wrapper .section1_p{
  font-size: 1.3em;
  color: rgb(148, 148, 148);
}










/* --------Responsive--------- */
/* XS */
@media (max-width: 767px) {
  .section1_wrapper{
    padding-top: 200px;
    padding-bottom: 100px;
  }
  .s1_right{
    padding-top: 0px !important;
  }
  .s1_img{
    padding-top: 10px;
  }
  .s1_right{
    text-align: center;
  }
  .s1_right a{
    width: 100%;
    margin-top: 20px;
  }
}