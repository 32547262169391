
.page_upper{
  position: relative;
}

.upper_absolute{
  position: absolute;
  height: 100px;
  width: 100%;
  padding: 15px 30px;
  top: 0;
  left: 0;
}
  