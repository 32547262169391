.nav_wrapper{
  width: 100%;
  padding: 20px;
}

.navbar_p{
  text-align: center;
  color: white;
  font-weight: bold;
}

.navbar_p{
  text-align: center;
  color: white;
  font-size: 1.3em;
}

.navbar_start{
  padding: 0 15px;
}

nav{
  border-radius: 20px;  
}
nav:before {
  content: "";
  width: calc(100% - 40px);
  height: 8px;
  background: #000;
  position: absolute;
  left: 20px;
  bottom: -8px;
  opacity: 0.2;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

.navbar-light .navbar-nav .show > .nav-link, .navbar-light .navbar-nav > .nav-link, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .nav-link{
  font-size: 1.3em !important;
}








/* --------Responsive--------- */
/* XS */
@media (max-width: 767px) {
  .navbar_p {
    margin-bottom: 0px;
  }
  .nav_wrapper {
    padding: 10px 0px;
  }
  .navbar_start{
    padding: 0px;
  }
  nav {
    margin-top: 25px;
  }
}


