.carousel_wrapper{
  width: 100%;
  height: 80vh;
}


.carousel-item img{
  height: 100vh;
}

.carousel_wrapper .carousel-caption {
  bottom: 20%;
}

.carousel_wrapper .carousel-caption h4 {
  text-shadow: 2px 2px #655e5e;
}

.carousel_wrapper .carousel-caption p {
  font-size: 1.6em;
  text-shadow: 2px 2px #655e5e46;
}






/* --------Responsive--------- */
/* XS */
@media (max-width: 767px) {
  .carousel_wrapper .carousel-caption {
    bottom: 15%;
  }

  .carousel_wrapper .carousel-caption h4 {
    font-size: 4.3em;
  }
  .w-100 {
    width: unset !important;
  }
}