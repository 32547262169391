.section2_wrapper{
  padding-bottom: 50px;
  background-color: #f9c416 !important;
}

.s2_right h1{
  color: #fff;
}

.s2_right{
  padding-top: 50px;
  padding-bottom: 50px;
}

.section2_p{
  margin-top: 20px;
  margin-bottom: 50px;
  text-align: justify;
}

.s2_right a{
  border-radius: 0px;
  padding: 15px 30px;
  background-color: rgb(2, 112, 17);
}
.s2_right a:hover, .s2_right a:active, .s2_right a:focus, .s2_right a:focus-within{
  background-color: rgb(250, 203, 23);
  border: 1px solid rgb(250, 203, 23);
  box-shadow: 0 0 0 0.2rem rgba(250, 203, 23, 0.5)
}

.s2_img{
  padding-top: 100px;
}

.section2_p{
  font-size: 1.3em;
  color: rgb(49, 49, 49);
}

.check_description h5{
  text-transform: uppercase;
  font-size: 1.3em;
  color: #fff;
}
.check_description p{
  font-size: 1.3em;
  color: #fff;
}


.s2_right .check_container i{
  border-radius: 25px;
  padding: 20px;
  background-color: #fff;
}









/* --------Responsive--------- */
/* XS */
@media (max-width: 767px) {
  .section1_wrapper{
    padding-top: 200px;
    padding-bottom: 100px;
  }
  .s2_right{
    padding-top: 20px;
  }
  .s2_img{
    padding-top: 10px;
  }
  .s2_right{
    text-align: center;
  }
  
  .s2_right .check_container i{
    margin-bottom: 10px;
  }
}