body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: 'Akaya Telivigala', cursive;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}




.new_footer_area {
  background: rgb(232, 197, 0);
}


.new_footer_top {
  font-size: 1.2em;
  padding: 50px 0px 30px;
  position: relative;
  overflow-x: hidden;
}
.new_footer_area .footer_bottom {
  padding-top: 5px;
  padding-bottom: 10px;
}
.footer_bottom {
  font-size: 1.1em;
  font-weight: 300;
  line-height: 20px;
  color: #ffffff;
  padding: 27px 0px;
}
.new_footer_top .company_widget p {
  font-size: 16px;
  font-weight: 300;
  line-height: 28px;
  color: #fff;
  margin-bottom: 20px;
}
.new_footer_top .company_widget .f_subscribe_two .btn_get {
  border-width: 1px;
  margin-top: 20px;
}
.btn_get_two:hover {
  background: transparent;
  color: rgb(2, 112, 17);
}
.btn_get:hover {
  color: #fff;
  background: rgb(2, 112, 17);
  border-color: rgb(2, 112, 17);
  -webkit-box-shadow: none;
  box-shadow: none;
}
a:hover, a:focus, .btn:hover, .btn:focus, button:hover, button:focus {
  text-decoration: none;
  outline: none;
}



.new_footer_top .f_widget.about-widget .f_list li a:hover {
  color: rgb(2, 112, 17);
}
.new_footer_top .f_widget.about-widget .f_list li {
  margin-bottom: 11px;
}
.f_widget.about-widget .f_list li:last-child {
  margin-bottom: 0px;
}
.f_widget.about-widget .f_list li {
  margin-bottom: 15px;
}
.f_widget.about-widget .f_list {
  margin-bottom: 0px;
}
.new_footer_top .f_social_icon a {
  width: 44px;
  height: 44px;
  line-height: 43px;
  background: transparent;
  border: 1px solid #fff;
  font-size: 24px;
}
.f_social_icon a {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  font-size: 14px;
  line-height: 45px;
  color: #fff;
  display: inline-block;
  background: #ebeef5;
  text-align: center;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.ti-facebook:before {
  content: "\e741";
}
.ti-twitter-alt:before {
  content: "\e74b";
}
.ti-vimeo-alt:before {
  content: "\e74a";
}
.ti-pinterest:before {
  content: "\e731";
}

.btn_get_two {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: rgb(2, 112, 17);
  border-color: rgb(2, 112, 17);
  color: #fff;
}

.btn_get_two:hover {
  background: transparent;
  color: rgb(2, 112, 17);
}

.new_footer_top .f_social_icon a:hover {
  background: rgb(2, 112, 17);
  border-color: rgb(2, 112, 17);
color:white;
}
.new_footer_top .f_social_icon a + a {
  margin-left: 4px;
}
.new_footer_top .f-title {
  margin-bottom: 30px;
  color: #fff;
  text-transform: uppercase;
  border-bottom: 1px solid white;
}
.f_600 {
  font-weight: 600;
}
.f_size_18 {
  font-size: 18px;
}

.new_footer_top .f_widget.about-widget .f_list li a {
  color: #fff;
}


.new_footer_top .footer_bg {
  position: absolute;
  bottom: 0;
  background: url("http://droitthemes.com/html/saasland/img/seo/footer_bg.png") no-repeat scroll center 0;
  width: 100%;
  height: 266px;
}

.new_footer_top .footer_bg .footer_bg_one {
  background: url("https://1.bp.blogspot.com/-mvKUJFGEc-k/XclCOUSvCnI/AAAAAAAAUAE/jnBSf6Fe5_8tjjlKrunLBXwceSNvPcp3wCLcBGAsYHQ/s1600/volks.gif") no-repeat center center;
  width: 330px;
  height: 105px;
background-size:100%;
  position: absolute;
  bottom: 0;
  left: 30%;
  -webkit-animation: myfirst 22s linear infinite;
  animation: myfirst 22s linear infinite;
}

.new_footer_top .footer_bg .footer_bg_two {
  background: url("https://1.bp.blogspot.com/-hjgfxUW1o1g/Xck--XOdlxI/AAAAAAAAT_4/JWYFJl83usgRFMvRfoKkSDGd--_Sv04UQCLcBGAsYHQ/s1600/cyclist.gif") no-repeat center center;
  width: 88px;
  height: 100px;
background-size:100%;
  bottom: 0;
  left: 38%;
  position: absolute;
  -webkit-animation: myfirst 30s linear infinite;
  animation: myfirst 30s linear infinite;
}



/*************footer End*****************/
